@import "./helpers/respond";
@import "./helpers/fluid-property";
@import "./helpers/rgba";
@import "./helpers/color";
@import "./helpers/base-columns";
@import "./helpers/section";
@import "./helpers/no-select";

// Hide from both screenreaders and browsers
@mixin hidden {
  display: none;
  visibility: hidden;
}

@mixin visible($state: "block") {
  display: unquote($state);
  visibility: visible;
}

// Hide only visually, but have it available for screenreaders
@mixin vh($focusable: false) {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  @if $focusable {
    @include vh-focusable;
  }
}

@mixin vh-reset {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

// Allow the element to be focusable when navigated to via the keyboard
@mixin vh-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout
@mixin invisible {
  visibility: hidden;
}

/*--------------------------------
  Form input placeholder text
  example:
  input,
  textarea {
    @include input-placeholder {
      color: $grey;
    }
  }
*/

@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

/*--------------------------------
  Retina images
  example:
  .element {
    @include retina {
      background-image: url(../img/background@2x.png);
    }
  }
*/

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx) {
    @content;
  }
}

/*--------------------------------
  CSS Triangle
  used for creating CSS only triangles
  example:
  .element {
    &:before {
      @include css-triangle(blue, down);
    }
  }
*/

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction==down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else 
  if $direction==up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else 
  if $direction==right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else 
  if $direction==left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

/*--------------------------------
  Responsive ratio
  Used for creating scalable elements that maintain the same ratio
  example:
  .element {
    @include responsive-ratio(400, 300);
  }
*/

@mixin ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + "%");

  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

/*--------------------------------
  Typography
  Text image replacement, with responsive ratio
  HTML:
  <h1 class="element">
    <span>Text to replace</span>
  </h1>
  example:
  .element {
    @include typography(200, 50, 'hello-world');
  }
*/

@mixin typography($w, $h, $file, $type: png, $overflow: hidden) {
  @include responsive-ratio($w, $h, true);
  position: relative;
  max-width: ($w / 10) * 1rem;
  background: url("/assets/img/typography/#{$file}.#{$type}") 50% 50% no-repeat;
  background-size: contain;

  span {
    @include hide-text(true, $overflow: $overflow);
  }
}
/* --------------------------------
  Colours
  background, colour, etc. match up with colour map in _variables.scss
  modify to suit per project */
@mixin colours($selector: false, $chain: "&--", $background: "base", $border: true) {
  @each $name, $values in $colours {
    #{$chain}#{$name} {
      @if $selector {
        #{$selector} {
          @if $background {
            background-color: map-get($values, $background);
            @if $border {
              border-color: darken(map-get($values, $background), 20%);
            }
          }
        }
      } @else {
        @if $background {
          background-color: map-get($values, $background);
        }
      }
    }
  }
}
@mixin hardware($backface: true, $perspective: 1000) {
  @if $backface {
    backface-visibility: hidden;
  }
  perspective: $perspective;
}
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// GRID
// --------------------------------------------------
// Mixin for building a column definition.
@mixin buildColumn($breakpointName, $columnWidth) {
  // Generates classes like md-5, sm-3, lg-7, etc. (".breakpointName-columnWidth").
  .col--#{$breakpointName}-#{$columnWidth} {
    @extend %baseColumn; // Only render the "--no-pad" flavor if padding is not disabled completely.
    @if (not $grid__disable-padding) {
      &--no-pad {
        @extend %baseColumnNoPad;
      }
    }
    @include respond-above($breakpointName) {
      // Calculate width as a percentage of the whole.
      $percentage: percentage($columnWidth / $grid__columns);
      max-width: $percentage; // Fix for IE
      flex: 0 0 $percentage;
    }
  }
  // Create horizontal offset classes.
  // Generates classes like md-offset-x, sm-offset-x, lg-offset-x (where x is a an integer).
  .col--#{$breakpointName}-offset-#{$columnWidth} {
    @extend %baseColumnOffset;
    @include respond-above($breakpointName) {
      // Calculates percentage based on $key-value and number of columns times 100.
      margin-left: percentage($columnWidth / $grid__columns);
    }
  }
}
